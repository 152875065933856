import { Link } from "./link";
import clsx from "clsx";

export function BreadCrumbs({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<nav
			aria-label="Breadcrumb"
			className={`mb-layout2 lg:mb-layout4 ${className || ""}`}
		>
			<ol>{children}</ol>
		</nav>
	);
}

export function Crumb({
	to,
	children,
	isCurrentPage = false,
}: {
	to?: string;
	children: React.ReactNode;
	isCurrentPage?: boolean;
}) {
	return (
		<li
			className={clsx(
				"ml-3 inline text-sm before:mr-3 before:font-medium before:text-grey-40 before:content-['>'] first-of-type:ml-0 first-of-type:before:content-none",
				{
					"font-medium text-grey-60 dark:text-grey-40": isCurrentPage,
				}
			)}
		>
			{!isCurrentPage && to ? (
				<Link
					className={
						"font-medium decoration-theme-primary underline-offset-8 hover:underline dark:text-grey-5"
					}
					to={to}
					trackingPosition="breadcrumb"
				>
					{children}
				</Link>
			) : (
				<>{children}</>
			)}
		</li>
	);
}
